import React, { useState } from "react";
import { AuthContext } from "./context/auth";
import NavDrawer from './components/NavDrawer';

function App(props) {
  
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);
  
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }
  
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <div>
        <NavDrawer authTokens={authTokens} />
      </div>
    </AuthContext.Provider>
  );
}

export default App;