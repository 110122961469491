import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'enrolled', type: 'number', headerName: 'Camp Projects', width: 180 },
  { field: 'registered', type: 'number', headerName: 'New Registrations', width: 180 },
];

const campData = [
  { id: 1, date: '03-01-2021', enrolled: 473, registered: 191 },
  { id: 2, date: '03-02-2021', enrolled: 647, registered: 343 },
  { id: 3, date: '03-03-2021', enrolled: 1012, registered: 562 },
  { id: 4, date: '03-04-2021', enrolled: 2259, registered: 839 },
  { id: 5, date: '03-05-2021', enrolled: 2664, registered: 1034 },
  { id: 6, date: '03-06-2021', enrolled: 2929, registered: 1190 },
  { id: 7, date: '03-07-2021', enrolled: 3215, registered: 1332 },
  { id: 8, date: '03-08-2021', enrolled: 3460, registered: 1525 },
  { id: 9, date: '03-09-2021', enrolled: 3741, registered: 1721 },
  { id: 10, date: '03-10-2021', enrolled: 4086, registered: 1946 },
  { id: 11, date: '03-11-2021', enrolled: 4416, registered: 2137 },
  { id: 12, date: '03-12-2021', enrolled: 4764, registered: 2324 },
  { id: 13, date: '03-13-2021', enrolled: 5336, registered: 2489 },
  { id: 14, date: '03-14-2021', enrolled: 5709, registered: 2661 },
  { id: 15, date: '03-15-2021', enrolled: 6082, registered: 2890 },
  { id: 16, date: '03-16-2021', enrolled: 6363, registered: 3126 },
  { id: 17, date: '03-17-2021', enrolled: 6971, registered: 3406 },
  { id: 18, date: '03-18-2021', enrolled: 7483, registered: 3656 },
  { id: 19, date: '03-19-2021', enrolled: 8306, registered: 3945 },
  { id: 20, date: '03-20-2021', enrolled: 8802, registered: 4192 },
  { id: 21, date: '03-21-2021', enrolled: 9222, registered: 4411 },
  { id: 22, date: '03-22-2021', enrolled: 9727, registered: 4695 },
  { id: 23, date: '03-23-2021', enrolled: 10197, registered: 4970 },
  { id: 24, date: '03-24-2021', enrolled: 11488, registered: 5330 },
  { id: 25, date: '03-25-2021', enrolled: 12025, registered: 5598 },
  { id: 26, date: '03-26-2021', enrolled: 12685, registered: 5861 },
  { id: 27, date: '03-27-2021', enrolled: 13258, registered: 6087 },
  { id: 28, date: '03-28-2021', enrolled: 13909, registered: 6331 },
  { id: 29, date: '03-29-2021', enrolled: 14838, registered: 6683 }
];

const CampStatsGrid = () => {
  
  return (
    <div style={{ height: 1800, width: '100%' }}>
      <DataGrid
        rows={campData}
        columns={columns}
        pageSize={31}
        sortModel={[
          {
            field: 'date',
            sort: 'desc',
          },
        ]}
      />
    </div>
  );
}
export default CampStatsGrid;