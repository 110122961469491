import React, { Component } from 'react';
import * as d3 from "d3";
import data from './april_2021.json';

class BarChart extends Component {
    componentDidMount() {
        this.drawChart();
    }

    drawChart() {

        var parseDate = d3.timeParse("%m/%d/%Y")
        //console.log(data);
        if (typeof (data[0]["Writing Groups"]) == "string") {
            data.forEach((d, i) => {
                d["Writing Groups"] = Number(d["Writing Groups"].replace(/,/g, ""));
                d["Camp Projects"] = Number(d["Camp Projects"].replace(/,/g, ""));
                d["Active Goals as of the date queried"] = Number(d["Active Goals as of the date queried"].replace(/,/g, ""));
                d["Active users in writing groups"] = Number(d["Active users in writing groups"].replace(/,/g, ""));
                d["Non-Camp goals that overlap with April by at least one day"] = Number(d["Non-Camp goals that overlap with April by at least one day"].replace(/,/g, ""));
                d["Total Goals with a 2021 Start Date"] = Number(d["Total Goals with a 2021 Start Date"].replace(/,/g, ""));
                d["New Sign-Ups"] = Number(d["New Sign-Ups"].replace(/,/g, ""));
                d["Date"] = d["Date"].replace(/-/g, "/");
                d["Date"] = parseDate(d["Date"]);
            });
        }

        console.log(data);

        < svg id="bar"></svg >

        const width = 800;
        const height = 500;
        const svg = d3.select("#bar")
            .attr("width", width)
            .attr("height", height)
        const margins = { top: 50, right: 80, bottom: 200, left: 50 };
        const chartWidth = width - margins.left - margins.right;
        const chartHeight = height - margins.top - margins.bottom;
        let annotations = svg.append("g").attr("id", "annotations");
        let chartArea = svg.append("g").attr("id", "points")
            .attr("transform", `translate(${margins.left},${margins.top})`);



        //add title
        chartArea.append("text")
            .attr("x", ((width / 2) - 70))
            .attr("y", (margins.top - 65))
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("text-decoration", "underline")
            .style("font-family", "Arial")
            .text("Camp Projects");



        // const noncampsExtent = d3.extent(data, d => d['Non-Camp goals exactly overlapping Camp']);
        // console.log(noncampsExtent)
        // const noncampScale = d3.scaleLinear().domain([noncampsExtent[0], (noncampsExtent[1] + 1000)]).range([chartHeight, 0]);


        const campsExtent = d3.extent(data, d => d['Camp Projects']);
        console.log(campsExtent)
        const campScale = d3.scaleLinear().domain([campsExtent[0], (campsExtent[1] + 1000)]).range([chartHeight, 0]);

        const dates = d3.map(data, d => d["Date"])
        const datesScale = d3.scaleBand()
            .domain(dates)
            .range([0, chartWidth])
            .padding(0.05);

        //const colorScale = d3.scaleOrdinal()

        //create and call y axis and grid lines
        let leftAxis = d3.axisLeft(campScale)
        let leftGridlines = d3.axisLeft(campScale)
            .tickSize(-chartWidth - 10)
            .tickFormat("")
        annotations.append("g")
            .attr("class", "y axis")
            .attr("transform", `translate(${margins.left - 10},${margins.top})`)
            .call(leftAxis)
        annotations.append("g")
            .attr("class", "y gridlines")
            .attr("transform", `translate(${margins.left - 10},${margins.top})`)
            .call(leftGridlines);

        //create and call x axis and axis labels
        let bottomAxis = d3.axisBottom(datesScale)
            .tickFormat(d3.timeFormat("%b %d"))
        annotations.append("g")
            .attr("class", "x axis")
            .attr("transform", `translate(${margins.left},${chartHeight + margins.top + 10})`)
            .call(bottomAxis)
            .selectAll("text")
            .attr("y", 0)
            .attr("x", 25)
            .attr("dy", ".35em")
            .attr("transform", "rotate(45)")
            .style("text-anchor", "hanging");

        //make rectangles
        chartArea.selectAll('rect.bar').data(data)
            .join('rect').attr('class', 'bar')
            .attr("fill", "steelblue")
            .attr("x", d => datesScale(d["Date"]))
            .attr("y", d => campScale(d["Camp Projects"]))
            .attr("height", d => campScale(0) - campScale(d["Camp Projects"]))
            .attr("width", datesScale.bandwidth());

    }

    render() {
        return <svg id="bar"></svg>

    }
}


export default BarChart;
