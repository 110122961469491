import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import logoImg from "../img/logo.svg";
import { Card, Logo, Form, Input, Button, Error } from "../components/AuthForm";
import { useAuth } from "../context/auth";
import { API_HOST } from "../environment";

const Login = (props) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();
  //const referer = props.location.state.referer || '/';
  const referer = '/';
  
  function postLogin(e) {
    e.preventDefault();
    axios.post(API_HOST+"/users/sign_in_admin", {
      identifier,
      password
    }).then(result => {
      if (result.status === 200) {
        setAuthTokens(result.data);
        setLoggedIn(true);
      } else {
        setIsError(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <Card>
      <Logo src={logoImg} />
      <Form onSubmit={postLogin}>
        <Input
          type="username"
          id="username"
          value={identifier}
          onChange={e => {
            setIdentifier(e.target.value);
          }}
          placeholder="username or email"
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <Button type="submit">Sign In</Button>
      </Form>
      { isError &&<Error>The username or password provided were incorrect!</Error> }
    </Card>
  );
}

export default Login;
