import React from "react";
//import axios from 'axios';
//import { Link, Redirect } from "react-router-dom";
import logoImg from "../img/logo.svg";
import { Card, Logo } from "../components/AuthForm";
//import { useAuth } from "../context/auth";

const Welcome = (props) => {

  return (
    <Card>
      <Logo src={logoImg} />
      <h1>Welcome!</h1>
    </Card>
  );
}

export default Welcome;