import React, { useState, useEffect } from "react";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useAuth } from "../context/auth";
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Cancel';
import { API_HOST } from "../environment";
import {ymdSubtractDays, ymdAddDays} from "../components/dateHelpers";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '800px',
  },
  table: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const EventStats = (props) => {
  
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [eventsListData, setEventsListData] = useState([]);
  const [eventId, setEventId] = useState('');
  const [eventPrepStartDate, setEventPrepStartDate] = useState('');
  const [eventData, setEventData] = useState([]);
  // track the target date for requesting data
  const [targetDate, setTargetDate] = useState(null);
  
  useEffect(() => {
    if (!isLoaded) {
      fetchEventsList();
    }
    if (loadingTable) {
      fetchData();//eventId,currentDate,eventEndDate);
    }
  });
  
  const { authTokens } = useAuth();
  
  const config = {
    headers: { Authorization: `Bearer ${authTokens.auth_token}`}
  };
  
  // Gets the list of links from the API
  const fetchData = async () => {
    const displayDate = targetDate;
    setLoadingTable(false);
    // define the api endpoint
    let endpoint = API_HOST+"/data/events/"+eventId+"?date="+targetDate;
    // get the endpoint data
    axios.get(endpoint, config).then(result => {
      if (result.status === 200) {
        let ed = []
        if (isNew) {
          setIsNew(false);
        } else {
          ed = eventData;
        }
        // override the result's date 
        result.data[0]=displayDate
        ed.push(result.data);
        setEventData(ed);
        
        //decrement the target date
        setTargetDate( ymdSubtractDays(targetDate,1) );
        
        if (isLoading && targetDate > eventPrepStartDate) {
          setLoadingTable(true);
        } else {
          setIsLoading(false);
        }
        
      } else {
        alert('Something went wrong. Please contact your tech department.')
      }
    }).catch(e => {
        alert('Something went wrong. Please contact your tech department.')
    });
  };
  //}, []);
  
  // Gets the list of links from the API
  const fetchEventsList = () => {
    //axios.get("https://staging.api.nanowrimo.org/data/events_list", config).then(result => {
    //axios.get("https://api.nanowrimo.org/data/events_list", config).then(result => {
    axios.get(API_HOST+"/data/events_list", config).then(result => {
      if (result.status === 200) {
        let events = [];
        for (let i=0; i<result.data.data.length; i++) {
          events.push({
            id: result.data.data[i].id,
            name: result.data.data[i].attributes.name,
            prep_starts_at: result.data.data[i].attributes['prep-starts-at'],
            starts_at: result.data.data[i].attributes['starts-at'],
            ends_at: result.data.data[i].attributes['ends-at'],
          });
        }
        setIsLoaded(true);
        setEventsListData(events);
      } else {
        alert("Something went wrong. Please contact your tech department.")
      }
    }).catch(e => {
        alert("Something went wrong. Please contact your tech department.")
    });
  };
  
  const handleChange = (event) => {
    setIsNew(true);
    setEventData([]);
    
    // set the component's eventId to the id of the selected event
    setEventId(event.target.value);
    
    for (let i=0; i<eventsListData.length; i++) {
      if (eventsListData[i].id===event.target.value) {
        // the selected event has been found
        setEventPrepStartDate(eventsListData[i].prep_starts_at);
        // recompute the targetDate
        // targetDate is (event.ends_at+5day) or nowDate ; whichever is older
        let now = new Date();
        var nowDate =now.toISOString().substring(0,10);
        var endsAt = ymdAddDays(eventsListData[i].ends_at,5);
        var target = (endsAt < nowDate) ? endsAt : nowDate;
        setTargetDate(target);
        // no need to continue the loop
        break;
      }
    }
    setLoadingTable(true);
    setIsLoading(true);
  }
  
  const handleCancel = (event) => {
    setLoadingTable(false);
    setIsLoading(false);
  }
  
  return (
    <div>
      <div><strong>Note:</strong> Prior to 2021 the creation date of project challenges wasn't set correctly, so the dates of new project creation is incorrect for older events. (The other data should be correct.) <a href="https://docs.google.com/spreadsheets/d/1dPyUXJnU-IcY7vwZ5pwq5eoEDhEkH3Ch87UsBn1M54I/edit" target="_blank" rel="noreferrer">Tim's spreadsheets</a> provide a better picture of historical project-creation data.</div>
      <hr /><FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Event</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={eventId}
          onChange={handleChange}
          disabled={isLoading}
        >
          {eventsListData.map((event) => (
            <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {isLoading && (
        <div style={{height: '72px'}}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={handleCancel}
          >
          Cancel
         </Button>
        </div>
      )}
        
      {eventId && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{width: '120px'}}>Date</TableCell>
                  <TableCell align="right">Total Projects</TableCell>
                  <TableCell align="right">New users since event prep began</TableCell>
                  <TableCell align="right">Writing groups</TableCell>
                  <TableCell align="right">Active users in writing groups</TableCell>
                  <TableCell align="right">Non-event goals that overlap event by at least one day</TableCell>
                  <TableCell align="right">Non-event goals exactly overlapping event</TableCell>
                  <TableCell align="right">Active goals as of the date queried</TableCell>
                  <TableCell align="right">Total goals started after January 1</TableCell>
                  <TableCell align="right">Event projects with positive wordcount</TableCell>
                  <TableCell align="right">Started %</TableCell>
                  <TableCell align="right">Event projects completed</TableCell>
                  <TableCell align="right">Won %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventData.map((row) => (
                  <TableRow key={row[0]}>
                    <TableCell component="th" scope="row">{row[0]}</TableCell>
                    <TableCell align="right">{row[1].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[2].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[3].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[4].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[5].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[6].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[7].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[8].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[9].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[10]}</TableCell>
                    <TableCell align="right">{row[11].toLocaleString()}</TableCell>
                    <TableCell align="right">{row[12]}</TableCell>
                  </TableRow>
                ))}
                {isLoading && (
                  <TableRow key="loading">
                    <TableCell component="th" colSpan="13" scope="row" align="center"><CircularProgress /></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </div>
  );
}
export default EventStats;
