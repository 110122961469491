import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import GridOnIcon from '@material-ui/icons/GridOn';
import Home from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
//import { Switch, Link } from 'react-router-dom';
import Welcome from '../pages/Welcome';
import Login from '../pages/Login';
import EventStats from '../pages/EventStats';
import CampStats from '../pages/CampStats';
import CampStatsGrid from '../pages/CampStatsGrid';
import ScatterPlot from '../pages/ScatterPlot';
import BarChart from '../pages/BarChart';
import PrivateRoute from '../PrivateRoute';
import { useAuth } from "../context/auth";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
//import SavedLink from '@material-ui/icons/Link';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const NavDrawer = (props) => {
  const { setAuthTokens } = useAuth();

  function logOut() {
    setAuthTokens('');
    handleDrawerClose();
  }
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const style = {
    backgroundColor: '#73ab9b',
  };

  const iconStyle = {
    position: 'absolute',
    right: '20px'
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={
            clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })
          }
          style={style}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              NaNoWriMo Data
            </Typography>
            {props.authTokens && (
              <div style={iconStyle}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  <MenuItem onClick={logOut}>Log Out</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          {props.authTokens && (
            <List>
              {['Home'].map((text, index) => (
                <ListItem button key={text} component={Link} to="/" onClick={handleDrawerClose}>
                  <ListItemIcon><Home /></ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
              {['Event Stats'].map((text, index) => (
                <ListItem button key={text} component={Link} to="/eventstats" onClick={handleDrawerClose}>
                  <ListItemIcon><BarChartIcon /></ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          )}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/campstats" component={CampStats} />
            <PrivateRoute path="/eventstats" component={EventStats} />
            <PrivateRoute path="/campstatsgrid" component={CampStatsGrid} />
            <PrivateRoute path="/scatterplot" component={ScatterPlot} />
            <PrivateRoute path="/barchart" component={BarChart} />
            <PrivateRoute path="/" component={Welcome} />
            <Route path="/" component={Login} />
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default NavDrawer;