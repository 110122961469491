import React, { Component } from 'react';
import * as d3 from "d3";
import data from './april_2021.json';

class ScatterPlot extends Component {
  componentDidMount() {
    this.drawChart();
  }

  drawChart() {

    var parseDate = d3.timeParse("%m/%d/%Y")
    console.log(data);
    if (typeof (data[0]["Writing Groups"]) == "string") {
      data.forEach((d, i) => {
        d["Writing Groups"] = Number(d["Writing Groups"].replace(/,/g, ""));
        d["Camp Projects"] = Number(d["Camp Projects"].replace(/,/g, ""));
        d["Active Goals as of the date queried"] = Number(d["Active Goals as of the date queried"].replace(/,/g, ""));
        d["Active users in writing groups"] = Number(d["Active users in writing groups"].replace(/,/g, ""));
        d["Non-Camp goals that overlap with April by at least one day"] = Number(d["Non-Camp goals that overlap with April by at least one day"].replace(/,/g, ""));
        d["Total Goals with a 2021 Start Date"] = Number(d["Total Goals with a 2021 Start Date"].replace(/,/g, ""));
        d["New Sign-Ups"] = Number(d["New Sign-Ups"].replace(/,/g, ""));
        d["Date"] = d["Date"].replace(/-/g, "/");
        d["Date"] = parseDate(d["Date"]);
      });
    }

    // data = data.filter((d) => { return d['Writing Groups'] != "0"; })

    console.log(data);


    < svg id="scatter"></svg >

    const width = 800;
    const height = 500;
    const margins = { "top": 10, "right": 10, "bottom": 50, "left": 50 };
    const chartWidth = width - margins.left - margins.right;
    const chartHeight = height - margins.top - margins.bottom;
    const svg = d3.select("#scatter")
      .attr("width", width)
      .attr("height", height)

    //add title
    svg.append("text")
      .attr("x", ((width / 2) - 70))
      .attr("y", (60))
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .style("text-decoration", "underline")
      .style("font-family", "Arial")
      .text("Writing Groups");

    //add labels place
    svg.append("text")
      .attr("id", "label")
      .attr("x", 600)
      .attr("y", 60)
      .attr("text-anchor", "end")
      .attr("alignment-baseline", "hanging")


    let scatter = svg.append("g")
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")");


    //scale for y axis
    const groupsExtent = d3.extent(data, d => d['Writing Groups']);
    console.log(groupsExtent)

    const groupsScale = d3.scaleLinear().domain([groupsExtent[0], (groupsExtent[1] + 50)]).range([chartHeight, 0]);


    //scale for x axis
    const datesExtent = d3.extent(data, d => d['Date']);
    console.log(datesExtent)
    const datesScale = d3.scaleTime().domain([datesExtent[0], datesExtent[1]]).range([0, chartWidth]);


    //y axis 
    let leftAxis = d3.axisLeft(groupsScale).tickFormat(d3.format(","));
    svg.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + (margins.left - 10) + "," + margins.top + ")")
      .call(leftAxis);

    //x axis
    let bottomAxis = d3.axisBottom(datesScale).tickFormat(d3.timeFormat("%b %d"));
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(" + margins.left + "," + (chartHeight + margins.top + 10) + ")")
      .call(bottomAxis);

    //connect points
    scatter.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#69b3a2")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function (d) { return datesScale(d["Date"]) })
        .y(function (d) { return groupsScale(d["Writing Groups"]) })
      )

    //draw circles
    data.forEach((d, i) => {
      let circles = scatter.append("circle")
        .attr("r", 4)
        .attr("cx", datesScale(d['Date']))
        .attr("cy", groupsScale(d['Writing Groups']))
        .style("fill", "darkblue")
        .attr("label", d["Writing Groups"])

      circles.on("mouseover", function () {

        d3.select(this).raise()
          .transition().duration(200)
          .attr("stroke", "black")
          .attr("stroke-width", 4)
          .attr("r", 6);

        d3.select("#label").text(d['Writing Groups']);

      })
      circles.on("mouseout", function () {

        d3.select(this)
          .transition().duration(200)
          .attr("stroke-width", 0)
          .attr("r", 4);

        d3.select("#label").text(d[""]);

      })
    });
  }

  render() {
    return <svg id="scatter"></svg>

  }
}


export default ScatterPlot;
