import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  container: {
    width: 500,
  },
  table: {
    width: 500,
  },
});

const campData = { camp_data: [
  { date: '03-01-2021', enrolled: 473, registered: 191 },
  { date: '03-02-2021', enrolled: 647, registered: 343 },
  { date: '03-03-2021', enrolled: 1012, registered: 562 },
  { date: '03-04-2021', enrolled: 2259, registered: 839 },
  { date: '03-05-2021', enrolled: 2664, registered: 1034 },
  { date: '03-06-2021', enrolled: 2929, registered: 1190 },
  { date: '03-07-2021', enrolled: 3215, registered: 1332 },
  { date: '03-08-2021', enrolled: 3460, registered: 1525 },
  { date: '03-09-2021', enrolled: 3741, registered: 1721 },
  { date: '03-10-2021', enrolled: 4086, registered: 1946 },
  { date: '03-11-2021', enrolled: 4416, registered: 2137 },
  { date: '03-12-2021', enrolled: 4764, registered: 2324 },
  { date: '03-13-2021', enrolled: 5336, registered: 2489 },
  { date: '03-14-2021', enrolled: 5709, registered: 2661 },
  { date: '03-15-2021', enrolled: 6082, registered: 2890 },
  { date: '03-16-2021', enrolled: 6363, registered: 3126 },
  { date: '03-17-2021', enrolled: 6971, registered: 3406 },
  { date: '03-18-2021', enrolled: 7483, registered: 3656 },
  { date: '03-19-2021', enrolled: 8306, registered: 3945 },
  { date: '03-20-2021', enrolled: 8802, registered: 4192 },
  { date: '03-21-2021', enrolled: 9222, registered: 4411 },
  { date: '03-22-2021', enrolled: 9727, registered: 4695 },
  { date: '03-23-2021', enrolled: 10197, registered: 4970 },
  { date: '03-24-2021', enrolled: 11488, registered: 5330 },
  { date: '03-25-2021', enrolled: 12025, registered: 5598 },
  { date: '03-26-2021', enrolled: 12685, registered: 5861 },
  { date: '03-27-2021', enrolled: 13258, registered: 6087 },
  { date: '03-28-2021', enrolled: 13909, registered: 6331 },
  { date: '03-29-2021', enrolled: 14838, registered: 6683 },
]}

const CampStats = () => {
  
  const classes = useStyles();
  
  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Camp Projects</TableCell>
            <TableCell align="right">New Registrations</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campData.camp_data.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="right">{row.enrolled}</TableCell>
              <TableCell align="right">{row.registered}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default CampStats;