const ymdAddDays = (ymd,days) => {
  return ymdModifyDay(ymd, "+", days);
}

const ymdSubtractDays = (ymd,days) => {
  return ymdModifyDay(ymd, "-", days);
}

const ymdModifyDay = (ymd, modifier, val) => {
  var date = new Date(ymd);
  if (modifier === "+") {
    date.setDate( date.getDate() + val ); 
  } else if (modifier==="-") {
    date.setDate( date.getDate() - val ); 
  }
  var y = date.getUTCFullYear();
  var m = date.getUTCMonth()+1;
  m = (m<10) ? "0"+m : m;
  var d = (date.getUTCDate()<10) ? "0"+date.getUTCDate() : date.getUTCDate();
  return `${y}-${m}-${d}`;
}

export {ymdAddDays, ymdSubtractDays }
